<template>
    <div class="apartments-list">
        <div
            class="list-container"
            v-if="
                checkRight('G_FICHES') ||
                checkRight('G_KEY') ||
                checkRight('G_KEY_MOBIL') ||
                checkRight('G_KIBOLT') ||
                checkRight('G_CONNECTIT') ||
                checkRight('G_APP') ||
                checkRight('G_CNIL') ||
                checkRight('G_APP_NOM') ||
                checkRight('G_LOGOS') ||
                checkRight('G_RESIDENCES')
            "
        >
            <h1>
                {{ $t("apartmentsList.title", { count: apartmentsCount }) }}
            </h1>
            <table class="table apartments-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>{{ $t("apartmentsList.arrayColumns.name") }}</th>
                        <th>
                            {{
                                $t("apartmentsList.arrayColumns.firstResident")
                            }}
                        </th>
                        <th class="mobile">
                            {{ $t("apartmentsList.arrayColumns.residents") }}
                        </th>
                        <th class="mobile">
                            {{ $t("apartmentsList.arrayColumns.badges") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-if="this.apartmentsCount < 1 && checkRight('G_APP')"
                        class="create-apartment-row"
                        @click="
                            openV1Modal('data/cage/action/cage_add', 'Act_Zone')
                        "
                    >
                        <td>
                            <span>
                                <div class="create-picto">
                                    <i class="fas fa-city"></i>
                                    <i class="fas fa-plus"></i>
                                </div>
                            </span>
                        </td>
                        <td class="create-title">
                            {{ $t("apartmentsList.create") }}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr
                        v-for="item in apartmentList.list"
                        :key="item.id"
                        @click="goToApartment(item.id)"
                        :class="{ lastApt: item.id == lastAppartId }"
                    >
                        <td><i class="fas fa-door-closed"></i></td>
                        <td class="name">
                            {{ item.nom }}
                            <span class="comment">
                                {{ item.comments }}
                            </span>
                        </td>
                        <td>{{ item.fiche_nom }}</td>
                        <td class="mobile">{{ item.fiches_count }}</td>
                        <td class="mobile">{{ item.cles_count }}</td>
                    </tr>
                </tbody>
            </table>

            <pagination
                v-show="pagesCount > 1"
                :pagesCount="pagesCount"
                :page="currentPage"
                v-on:new-current-page="currentPage = $event"
            >
            </pagination>
        </div>
        <div class="error-container" v-else>
            <i class="fas fa-exclamation-circle"></i>
            <p>{{ $t("apartmentsList.noAuthorizations") }}</p>
        </div>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"
import Pagination from "@/components/basic/Pagination.vue"
import { getApartmentList } from "@/services/intratone/apartment"

export default {
    name: "ApartmentsList",
    components: {
        Pagination,
    },
    mixins: [v1mixin],
    data() {
        return {
            //last residence
            lastAppartId: null,
            // Pagination
            currentPage: 1,
            pagesCount: 1,
            limitPerPage: 100,
            apartmentsCount: 10,
            apartmentList: [],
        }
    },
    props: {
        apartments: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    methods: {
        setQuery() {
            let params = {}
            if (this.currentPage > 1) {
                params["page"] = this.currentPage
            }
            this.$router.push({
                query: params,
            })
        },
        async getApartmentList() {
            const values = await getApartmentList({
                idcage: this.apartments["list"][0]["idcage"],
                page: this.currentPage,
                limit: this.limitPerPage,
                stat: "summary",
            })
            this.apartmentList = values["data"]
        },
        goToApartment(id) {
            this.$router.push({
                name: "Apartment",
                params: {
                    id: id,
                },
            })
        },
    },
    created() {
        let params = { page: this.apartments.page }
        if (this.$store.getters["account/getAppartId"] !== null) {
            this.lastAppartId = this.$store.getters["account/getAppartId"]
        }
        let pages = parseInt(this.apartments._pages, 10)
        if (params.page && params.page > pages) {
            this.currentPage = pages
            this.setQuery()
        }

        this.pagesCount = pages > 0 ? pages : 1
        this.apartmentsCount = this.apartments["_count"]
    },
    mounted() {
        this.apartmentList = this.apartments
    },
    computed: {
        indexFirst() {
            return 1 + (this.currentPage - 1) * this.limitPerPage
        },
        indexLast() {
            return Math.min(
                this.limitPerPage + (this.currentPage - 1) * this.limitPerPage,
                this.centralUnitsCount
            )
        },
        // Parameters for central units query
        parameters() {
            let params = {
                limit: this.limitPerPage,
            }
            if (this.currentPage > 1) {
                params["page"] = this.currentPage
            }
            return params
        },
        // Query from URL
        urlQuery() {
            return this.$route.query
        },
    },
    watch: {
        currentPage: {
            handler: async function () {
                await this.getApartmentList()
            },
            deep: true,
        },
        apartments: {
            handler: async function () {
                this.apartmentList = this.apartments

                let page = parseInt(this.apartments._page, 10)
                this.currentPage = page

                let pages = parseInt(this.apartments._pages, 10)
                this.pagesCount = pages > 0 ? pages : 1
                this.apartmentsCount = this.apartments["_count"]
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

.apartments-list {
    padding: 30px 0 30px 0;
    border-radius: 30px;
    box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);

    .error-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;

        i {
            color: $orange-neutral;
            font-size: $big;
        }

        p {
            margin: 0;
            font-size: $big;
            font-family: $font-avenir-medium;
        }
    }

    h1 {
        font-size: $normal;
        font-family: "Avenir Heavy";
        text-align: center;
        margin-bottom: 35px;
    }

    .apartments-table {
        border-color: $grey-neutral;
        width: 90%;
        margin-left: auto;
        margin-right: auto;

        .name {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
        }

        .comment {
            font-size: $small;
            font-style: italic;
            @include line-clamp(5, 0.9em);
        }

        thead {
            border: none;

            tr {
                border-color: $grey-neutral;

                th {
                    font-size: $normal;
                }
            }
        }

        tbody {
            border-color: $grey-neutral;

            tr {
                border-color: $grey-neutral;
                border-bottom: 1px solid $grey-neutral;
                transition: background-color 100ms, color 100ms;

                &:hover {
                    td {
                        background-color: $grey-light;
                        cursor: pointer;
                        color: $orange-neutral;

                        transition: background-color 100ms, color 100ms;
                    }
                }

                td {
                    vertical-align: middle;
                    border: none;
                    padding: 10px;
                    font-size: $normal;
                }

                td:first-child {
                    text-align: center;
                }
            }

            .create-apartment-row {
                td {
                    transition: 150ms;
                }
                &:hover {
                    td {
                        background-color: $orange-residence-dark;
                        transition: 150ms all;
                    }
                }
                .create-picto {
                    margin-right: 0px;
                    margin-left: auto;
                    color: $orange-neutral;
                    height: 43px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .fa-city {
                        font-size: $big;
                    }

                    .fa-plus {
                        font-size: $normal;
                        padding: 0 0 20px 0;
                    }
                }

                .create-title {
                    font-size: $normal;
                    color: $orange-neutral;
                    font-family: $font_avenir_heavy;
                }
            }
        }
    }
}

.mobile {
    @media all and (max-width: 768px) {
        display: none;
    }
}
</style>
