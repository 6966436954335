import httpClient from '../httpClient'

import { formatData } from '../request'
import { encodeRequestParameters } from '../request'

import { apartmentSummaryById } from '../formats'

/**
 * Returns a specific apartment and its global related informations
 */
export function getApartmentSummaryById(apartmentId) {
    const data = formatData(apartmentSummaryById)
    const params = encodeRequestParameters(data)
    return httpClient.get('/appart/' + apartmentId + '/summary', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}

/**
 * Returns a list of apartment
 */
export function getApartmentList(listParam) {
    // const data = formatData(apartmentSummaryById)
    const params = encodeRequestParameters(listParam)
    return httpClient.get('/appart', {params})
        .then((response) => {
            return response
        })
        .catch((error) => {
            throw error
        })
}
